export default {
  methods: {
    addFaqItem({ obj, lang }) {
      const item = obj[lang];
      let array = [];
      if (["string", "null"].includes(typeof item)) this[obj]["faq"][lang] = {};
      if (Array.isArray(obj[lang].items)) array = obj[lang].items;
      array.push({
        question: "",
        answer: "",
      });
      obj[lang].items = array;
    },
    deleteFaqItem({ obj, lang, index }) {
      try {
        obj[lang].items.splice(index, 1);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
