<template>
  <editor :value="value" :config="editorConfig" @input="handleChange" />
</template>

<script>
import CKEditor from "ckeditor4-vue";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: { editor: CKEditor.component },
  data() {
    return {
      editorConfig: {
        allowedContent: true,
        versionCheck: false,
      },
    };
  },
  methods: {
    handleChange(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style scoped></style>
