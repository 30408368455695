<template>
  <div>
    <v-btn
      small
      outlined
      @click="
        cancelModal = true;
        back = true;
      "
    >
      <v-icon class="me-2">mdi-arrow-left</v-icon> К списку страниц
    </v-btn>
    <div v-if="loader" class="text-center py-10">
      <v-progress-circular :size="50" indeterminate></v-progress-circular>
    </div>
    <template v-if="pageData && !loader">
      <div class="text-h4 pt-5 mb-1">
        Редактирование страницы "{{ pageData.name }}"
      </div>
      <p class="mb-10 accent--text">* - обязательное поле</p>
      <v-row class="mb-2">
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="pageData.name"
            ref="pageData.name"
            label="Название*"
            outlined
            dense
            :rules="[rules.required]"
            @blur="slugPaste(pageData.name, 'pageData')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="pageData.slug"
            ref="pageData.slug"
            label="slug*"
            outlined
            dense
            :rules="[rules.required]"
            @blur="slugPaste(pageData.name, 'pageData')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <div class="d-inline-block pt-1">
            <v-switch
              color="primary"
              class="mt-0"
              v-model="pageData.visibility"
              :ripple="false"
              label="Видимость"
            ></v-switch>
          </div>
        </v-col>
      </v-row>
      <div class="text-h5 mb-5">Контент страницы</div>
      <v-card class="mb-5 pa-5">
        <v-tabs>
          <v-tab v-for="lang in languagesList" :key="lang.iso">
            <div class="d-flex align-center">
              <country-flag :country="lang.icon" class="ma-0 elevation-3" />
              {{ lang.name }}
            </div>
          </v-tab>
          <v-tab-item
            v-for="lang in languagesList"
            :key="lang.iso + '_tab_content'"
            eager
            class="pt-3"
          >
            <div v-for="field in fieldsEditPages" :key="field.name">
              <div class="font-weight-bold">
                {{ field.field }} {{ field.required ? "*" : "" }}
              </div>
              <component
                :is="field.component"
                :ref="`pageData.${field.name}.${lang.iso}`"
                v-model="pageData[field.name][lang.iso]"
                outlined
                dense
                required
                :rules="field.required ? [rules.required] : [() => true]"
              ></component>
            </div>

            <div class="text-h6 mb-0">Добавить блок</div>
            <div class="mb-2">
              <small class="accent--text"
                >на странице должен быть минимум 1 контентный блок</small
              >
            </div>
            <v-row dense class="mb-5">
              <v-col v-for="block in blocksList" :key="block.name" cols="auto">
                <v-btn outlined @click="addNewBlock(block)">
                  {{ block.title }}
                </v-btn>
              </v-col>
            </v-row>
            <component
              :is="getBlockName(block.block_name)"
              v-for="(block, i) in pageData.constructor[lang.iso]"
              :block="block"
              :block-path="`constructor[${[lang.iso]}][${i}]`"
              :block-place="`[${i}]`"
              :key="`pageBlock_${i}`"
              @remove="removeBlock(i)"
            >
              <template #drag>
                <v-btn
                  icon
                  small
                  @click="toPositionBlock({ direction: 'up', blockIndex: i })"
                  ><v-icon>mdi-arrow-up-bold</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="toPositionBlock({ direction: 'down', blockIndex: i })"
                  ><v-icon>mdi-arrow-down-bold</v-icon>
                </v-btn>
              </template>
            </component>
            <b>SEO текст</b>
            <editor
              :value="pageData.content_description[lang.iso]"
              @input="(v) => (pageData.content_description[lang.iso] = v)"
            />
            <v-row class="py-5" dense align="center">
              <v-col cols="auto"><b class="mt-20">FAQ блок</b></v-col>
              <v-col cols="auto">
                <v-btn
                  @click="
                    addFaqItem({
                      obj: faq,
                      lang: lang.iso,
                    })
                  "
                  >Добавить</v-btn
                >
              </v-col>
              <v-col cols="12" class="pt-2">
                <v-text-field
                  v-model="faq[lang.iso].title"
                  label="Заголовок блока(h2)"
                  :rules="[rules.max(100)]"
                  counter="100"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-for="(item, ind) in faqItems(lang.iso)"
                :key="`faq_${lang.iso}_${ind}`"
                cols="12"
              >
                <b class="mb-5 me-3">{{ `Вопрос #${ind + 1}` }}</b>
                <v-icon
                  small
                  @click="
                    deleteFaqItem({
                      obj: faq,
                      lang: lang.iso,
                      index: ind,
                    })
                  "
                >
                  mdi-delete
                </v-icon>
                <v-text-field
                  v-model="item.question"
                  :label="`Вопрос #${ind + 1}`"
                  class="mt-3"
                  :rules="[rules.max(250)]"
                  counter="250"
                  outlined
                  dense
                ></v-text-field>
                <v-textarea
                  v-model="item.answer"
                  :label="`Ответ #${ind + 1}`"
                  :rules="[rules.max(5000)]"
                  counter="5000"
                  hint="текст или HTML разметка"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <v-row class="pb-10">
        <v-col cols="auto">
          <v-btn color="accent" large @click="save">Сохранить</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="accent" large outlined @click="cancelModal = true"
            >Отмена</v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn :href="pageData.prev_link" class="me-3" large target="_blank"
            >Предпросмотр</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <v-dialog v-model="cancelModal" max-width="500px">
      <template>
        <v-card class="pa-1">
          <v-card-title
            class="text-h5 text-center justify-center flex-column mb-5"
          >
            Внесенные изменения <br />не будут сохранены
          </v-card-title>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn color="accent" outlined @click="cancelModal = false"
              >Нет</v-btn
            >
            <v-btn color="accent" @click="leave">Да</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";
import faqSeo from "@/mixins/faq-seo";
import CountryFlag from "vue-country-flag";
import BlockContent from "@/components/Content/Blocks/BlockContent";
import BlockTable from "@/components/Content/Blocks/BlockTable";
import api from "../../../api";
import { mapActions, mapGetters } from "vuex";
import VTextField from "vuetify/lib/components/VTextField";
import VTextarea from "vuetify/lib/components/VTextarea";
import Editor from "@/components/Editor";

export default {
  name: "PageEdit",
  mixins: [rules, slug, faqSeo],
  components: {
    BlockTable,
    BlockContent,
    CountryFlag,
    VTextarea,
    VTextField,
    Editor,
  },
  data() {
    return {
      cancelModal: false,
      back: false,
      isLeave: false,
      routeTo: null,
      fieldsLang: ["title"],
      fieldValid: ["slug", "name"],
      fieldsEditPages: [
        {
          field: "SEO Title",
          name: "seo_title",
          component: "v-text-field",
        },
        {
          field: "SEO Description",
          name: "seo_description",
          component: "v-textarea",
        },
        {
          field: "H1",
          name: "title",
          component: "v-text-field",
          required: true,
        },
      ],
      faq: {},
      loader: true,
      editorConfig: {
        allowedContent: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      languagesList: "languagesList",
      blocksList: "pageEdit/blocksList",
      pageData: "pageEdit/pageData",
      isBlocksFill: "pageEdit/isBlocksFill",
    }),
    faqItems: (vm) => {
      return (lang) => {
        return vm.faq[lang].items;
      };
    },
  },
  created() {
    this.faq = this.createFaq();
    this.setPage();
  },
  beforeRouteLeave(to, from, next) {
    if (this.routeTo || this.isLeave) next();
    else {
      this.cancelModal = true;
      this.routeTo = to;
      next(false);
    }
  },
  methods: {
    ...mapActions("pageEdit", [
      "addNewBlock",
      "removeBlock",
      "toPositionBlock",
    ]),
    getBlockName(name) {
      switch (name) {
        case "table_block":
          return "block-table";
        default:
          return "block-content";
      }
    },
    async save() {
      let formHasErrors = this.validationObject(this.pageData, "pageData");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      if (!this.isBlocksFill) {
        this.$store.dispatch("showSnackbar", {
          msg: "Пустой контент, добавьте контентный блок",
          color: "error",
          show: true,
        });
        return;
      }
      try {
        const data = this.pageData;
        data.faq = this.faq;
        await api.content.pageUpdate(this.pageData.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Страница сохранена`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        let msgEr = "Ошибка сохранения";
        if (e?.response?.data?.errors) {
          msgEr = JSON.stringify(e.response.data.errors);
        }
        this.$store.dispatch("showSnackbar", {
          msg: msgEr,
          color: "error",
          show: true,
        });
      }
    },
    async setPage() {
      await this.$store.dispatch("pageEdit/getBlockList");
      try {
        await this.$store.dispatch(
          "pageEdit/getPageData",
          this.$route.params.id
        );
        this.languagesList.forEach((lang) => {
          if (
            this.pageData.faq[lang.iso] !== null &&
            !Array.isArray(this.pageData.faq[lang.iso])
          ) {
            this.faq[lang.iso] = this.pageData.faq[lang.iso];
          }
        });
        await this.$nextTick();
        this.loader = false;
      } catch (e) {
        console.error(e);
      }
    },
    leave() {
      if (this.back) {
        this.isLeave = true;
        this.$router.push({ name: "ContentPages" });
      } else if (this.routeTo) {
        this.$router.push(this.routeTo);
      } else {
        this.cancelModal = false;
        this.setPage();
      }
    },
    createFaq() {
      const faq = {};
      this.languagesList.forEach((lang) => {
        faq[lang.iso] = {
          title: "",
          items: [],
        };
      });
      return faq;
    },
  },
};
</script>

<style scoped></style>
