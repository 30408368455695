<template>
  <div>
    <v-card class="pa-5 mb-5" outlined>
      <v-row dense align="center" class="mb-1">
        <v-col v-if="drag" cols="auto">
          <slot name="drag"> </slot>
        </v-col>
        <v-col cols="auto">
          <b>Блок:{{ block.title }}*</b>
        </v-col>
        <v-col cols="auto">
          <div class="d-inline-block ps-3">
            <v-switch
              v-model="block.visibility"
              color="primary"
              class="mt-0"
              hide-details
              :ripple="false"
              label="Видимость"
            ></v-switch>
          </div>
        </v-col>
        <v-col cols="auto ms-auto">
          <v-btn icon small @click="remove"><v-icon>mdi-delete</v-icon> </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mb-3"></v-divider>
      <div class="font-weight-bold">
        {{ block.fields[0].title }}
      </div>
      <v-text-field
        v-model.trim="block['fields'][0].value"
        outlined
        dense
      ></v-text-field>
      <v-row dense class="mb-4" align="center">
        <v-col cols="auto">
          <div class="d-flex align-center">
            <span class="me-2">Колонка</span>
            <v-btn :disabled="updateCols" icon @click="formatCols(-1)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn :disabled="updateCols" icon @click="formatCols(1)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="auto">
          <v-btn class="ms-3" :height="40" @click="addBlock">
            <v-icon class="me-2">mdi-plus</v-icon>
            <span style="text-transform: none">Добавить строку</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-for="(row, i) in rows" :key="`row_${i}`" dense>
        <v-col v-for="(col, j) in row.blocks" :key="`col_${i}_${j}`">
          <v-text-field
            v-model.trim.lazy="col.fields[0].value"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col v-if="i > 0" cols="auto">
          <v-btn
            icon
            @click="REMOVE_BLOCK_DEEP({ parent: block, blockIndex: i })"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
const MIN_CELL = 1;
const MAX_CELL = 8;
export default {
  name: "BlockTable",
  props: {
    block: {
      type: Object,
      default: () => {},
    },
    blockPath: {
      type: [String, Number],
      default: 0,
    },
    blockPlace: {
      type: String,
      default: "",
    },
    drag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pending: false,
      defaultCol: null,
      updateCols: false,
    };
  },
  computed: {
    rows() {
      return this.block.blocks || [];
    },
    cellCount() {
      return this.rows[0].blocks.length;
    },
  },
  created() {
    if (!this.block.fields.length) {
      this.block.fields = [
        ...this.$store.getters["pageEdit/blocksList"].find(
          (b) => b.block_name === "table_block"
        ).fields,
      ];
    }
    this.defaultCol = JSON.parse(JSON.stringify(this.rows[0].blocks[0]));
    this.defaultCol.fields[0].value = "";
    this.ADD_BLOCKS_COLLECTION({
      blocks: this.block,
      value: {
        path: this.blockPath,
        place: this.blockPlace,
      },
    });
    this.rows.forEach((row, i) => {
      this.ADD_BLOCKS_COLLECTION({
        blocks: row,
        value: {
          path: `${this.blockPath}.blocks[${i}]`,
          place: `${this.blockPlace}.blocks[${i}]`,
        },
      });
    });
  },
  methods: {
    ...mapMutations("pageEdit", [
      "ADD_BLOCKS_COLLECTION",
      "ADD_NEW_BLOCK_DEEP",
      "REMOVE_BLOCK_DEEP",
      "ADD_BLOCKS_COLLECTION",
    ]),
    addBlock() {
      const newBlock = JSON.parse(
        JSON.stringify(this.rows[this.rows.length - 1])
      );
      newBlock.blocks.forEach((block) => {
        block.fields.forEach((field) => {
          field.value = "";
        });
      });
      newBlock.blocks[0].fields.forEach((item) => {
        item.value = "";
      });
      this.ADD_NEW_BLOCK_DEEP({
        parent: this.block,
        newBlock,
        collectionValue: {
          path: `${this.blockPath}.blocks[${this.rows.length}]`,
          place: `${this.blockPlace}.blocks[${this.rows.length}]`,
        },
      });
    },
    async remove() {
      this.$emit("remove");
    },
    formatCols(inc) {
      if (this.updateCols) return;
      let count = this.cellCount;
      if (inc < 0 && count > MIN_CELL) count--;
      else if (inc > 0 && count < MAX_CELL) count++;
      const newCells = count - this.cellCount;
      if (newCells === 0) return;
      this.updateCols = true;
      if (newCells > 0) {
        this.rows.forEach((row) => {
          this.ADD_NEW_BLOCK_DEEP({
            parent: row,
            newBlock: this.defaultCol,
          });
        });
      } else if (this.cellCount > 1) {
        this.rows.forEach((row) => {
          this.REMOVE_BLOCK_DEEP({
            parent: row,
            blockIndex: row.blocks.length - 1,
          });
        });
      }
      this.$nextTick(() => {
        this.updateCols = false;
      });
    },
  },
};
</script>

<style>
#cell-count {
  text-align: center !important;
  width: 40px;
}
</style>
