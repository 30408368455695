import isMobilePhone from "validator/es/lib/isMobilePhone";

function isIp(ip) {
  const arrIp = ip.split(".");
  if (arrIp.length !== 4) return false;
  for (let oct of arrIp) {
    if (isNaN(oct) || Number(oct) < 0 || Number(oct) > 255) return false;
  }
  return true;
}
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Поле обязательное для заполнения",
        requiredCombobox: (value) =>
          value.length > 0 || "Поле обязательное для заполнения",
        sameAs: (value, as, text) => value === as || `${text}`,
        isIp: (ip) => isIp(ip) || "Введите верный IP адрес",
        isPhone: (value) =>
          !value ||
          isMobilePhone(value) ||
          value === "" ||
          "Телефон введен не верно",
        firstSlash: (value) => value[0] === "/" || "Должен начинаться / ",
        max(length) {
          return (value) => {
            if (typeof value === "string" && value?.length) {
              return (
                value.length <= length ||
                `Максимальное кол-во символов ${length}`
              );
            } else {
              return true;
            }
          };
        },
      },
    };
  },
  methods: {
    validationObject(object, objectName, name) {
      let error = false;
      Object.keys(object).forEach((f) => {
        if (
          typeof object[f] === "object" &&
          !Array.isArray(object[f]) &&
          object[f] !== null
        ) {
          const error2 = this.validationObject(object[f], objectName, f);
          if (error2) error = true;
        } else {
          const refKey = name
            ? `${objectName}.${name}.${f}`
            : `${objectName}.${f}`;
          const component = this.$refs[refKey];
          if (component) {
            if (Array.isArray(component)) {
              component.forEach((el) => {
                if (!el.validate(true)) error = true;
              });
            } else {
              if (!component.validate(true)) error = true;
            }
          }
        }
      });
      return error;
    },
    resetErrors(object, objectName, name) {
      Object.keys(object).forEach((f) => {
        if (
          typeof object[f] === "object" &&
          !Array.isArray(object[f]) &&
          object[f] !== null
        ) {
          this.resetErrors(object[f], objectName, f);
        } else {
          const refKey = name
            ? `${objectName}.${name}.${f}`
            : `${objectName}.${f}`;
          const component = this.$refs[refKey];
          if (component) {
            if (Array.isArray(component)) {
              component.forEach((el) => {
                el.resetValidation();
              });
            } else {
              component.resetValidation();
            }
          }
        }
      });
    },
  },
};
