<template>
  <div>
    <v-card class="pa-5 mb-5" outlined>
      <v-row dense align="center" class="mb-1">
        <v-col v-if="drag" cols="auto">
          <slot name="drag"> </slot>
        </v-col>

        <v-col cols="auto">
          <b>Блок:{{ block.title }}</b>
        </v-col>

        <v-col cols="auto">
          <div class="d-inline-block ps-3">
            <v-switch
              v-model="block.visibility"
              color="primary"
              class="mt-0"
              hide-details
              :ripple="false"
              label="Видимость"
            ></v-switch>
          </div>
        </v-col>

        <v-col v-if="!notRemove" cols="auto ms-auto">
          <v-btn icon small @click="remove"><v-icon>mdi-delete</v-icon> </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mb-3"></v-divider>

      <div v-for="(field, i) in fields" :key="`field_${i}`">
        <div class="font-weight-bold">{{ field.title }}</div>

        <component
          v-if="field.type === 'text' || field.type === 'textarea'"
          :is="field.type === 'text' ? 'v-text-field' : 'v-textarea'"
          v-model.trim="field.value"
          outlined
          dense
        ></component>

        <v-row v-else-if="field.type === 'image'" dense>
          <v-col cols="10" sm="8" md="5">
            <v-file-input
              :value="fileName(field.value)"
              label="Изображение (до 1mb)"
              :disabled="pending"
              accept="image/png, image/jpeg, svg"
              :clearable="false"
              outlined
              dense
              :hide-input="!!field.value"
              :prepend-icon="!field.value ? 'mdi-image-plus' : ''"
              :id="`file_${i}`"
              :ref="`file_${i}`"
              @change="(v) => imgChange(v, field)"
            ></v-file-input>
            <label :for="`file_${i}`">
              <v-img
                v-if="field.value && field.value !== ''"
                :src="field.value"
                :aspect-ratio="16 / 9"
              ></v-img>
            </label>
          </v-col>
          <v-col cols="auto">
            <v-btn icon :ripple="false" @click="deleteFile(field)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <editor
          v-else-if="field.type === 'html_editor'"
          :value="field.value"
          @input="(v) => (field.value = v)"
        />
      </div>

      <v-row dense class="mb-5">
        <v-col v-for="(block, i) in newBlocks" :key="`btn_${i}`" cols="auto">
          <v-btn @click="addBlock(block)">
            <v-icon class="me-2">mdi-plus</v-icon>
            <span style="text-transform: none">Блок:{{ block.title }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <div v-for="(b, i) in blocks" :key="`block_${i}`">
        <block-content
          :block="b"
          :block-path="`${blockPath}.blocks[${i}]`"
          :block-place="`${blockPlace}.blocks[${i}]`"
          :not-remove="i < newBlocks.length"
          :drag="false"
          :ref="`block_${i}`"
          class="mt-5"
          @remove="REMOVE_BLOCK_DEEP({ parent: block, blockIndex: i })"
        ></block-content>
      </div>
    </v-card>
  </div>
</template>

<script>
import BlockContent from "@/components/Content/Blocks/BlockContent";
import api from "../../../../api";
import { mapMutations } from "vuex";
import VTextField from "vuetify/lib/components/VTextField";
import VTextarea from "vuetify/lib/components/VTextarea";
import Editor from "@/components/Editor";
const FILE_SIZE = 1048576;

export default {
  name: "BlockContent",
  components: { BlockContent, VTextField, VTextarea, Editor },
  props: {
    block: {
      type: Object,
      default: () => {},
    },
    blockPath: {
      type: [String, Number],
      default: 0,
    },
    blockPlace: {
      type: String,
      default: "",
    },
    drag: {
      type: Boolean,
      default: true,
    },
    notRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pending: false,
      editorConfig: {
        allowedContent: true,
      },
    };
  },
  computed: {
    newBlocks() {
      const res = [];
      const uniq = [...new Set(this.block.blocks.map((b) => b.name))];
      uniq.forEach((name) => {
        const block = this.block.blocks.find((b) => b.name === name);
        if (block) res.push(JSON.parse(JSON.stringify(block)));
      });
      return res;
    },
    fields() {
      return this.block.fields;
    },
    blocks() {
      return this.block.blocks;
    },
  },
  created() {
    if (!this.block.fields.length) {
      this.block.fields = [
        ...this.$store.getters["pageEdit/blocksList"].find(
          (b) => b.block_name === this.block.block_name
        ).fields,
      ];
    }
    this.ADD_BLOCKS_COLLECTION({
      blocks: this.block,
      value: {
        path: this.blockPath,
        place: this.blockPlace,
      },
    });
  },
  methods: {
    ...mapMutations("pageEdit", [
      "ADD_BLOCKS_COLLECTION",
      "ADD_NEW_BLOCK_DEEP",
      "REMOVE_BLOCK_DEEP",
    ]),
    addBlock(block) {
      const newBlock = JSON.parse(JSON.stringify(block));
      newBlock.fields.forEach((f) => (f.value = ""));
      this.ADD_NEW_BLOCK_DEEP({
        parent: this.block,
        newBlock,
      });
    },
    async remove() {
      this.deleteAllFiles();
      this.$emit("remove");
    },
    async uploadFile(file, field) {
      if (!file.name) return;
      if (file.size > FILE_SIZE) {
        this.$store.dispatch("showSnackbar", {
          msg: `Размер файла превышает 1mb`,
          color: "error",
          show: true,
        });
        return;
      }
      try {
        const data = new FormData();
        data.append("files[0][file]", file);
        data.append("files[0][json_path]", "file");
        const res = await api.content.filesUpload(this.$route.params.id, data);
        this.$set(field, "value", res.data.files[0].file);
      } catch (e) {
        console.error(e);
      }
    },
    async deleteAllFiles() {
      this.fields.forEach((field) => {
        this.deleteFile(field);
      });
      this.blocks.forEach((b, i) => {
        const block = this.$refs[`block_${i}`];
        if (block) block[0].deleteAllFiles();
      });
    },
    async deleteFile(field) {
      if (!field.value || field.type !== "image") return;
      try {
        await api.content.filesDelete(this.$route.params.id, {
          files: [{ file: field.value }],
        });
        this.$set(field, "value", "");
      } catch (e) {
        console.error(e);
      }
    },
    async imgChange(file, field) {
      this.pending = true;
      if (file) {
        await this.uploadFile(file, field);
      } else {
        await this.deleteFile(field);
      }
      this.pending = false;
    },
    fileName(value) {
      if (typeof value === "string") {
        return new File([""], value, { type: "image/png" });
      }
      return value;
    },
  },
};
</script>
