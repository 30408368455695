import slug from "@/helpers/slug";

export default {
  methods: {
    slugValue(val, slugKeys = "editedItem") {
      if (typeof slugKeys === "object") {
        this[slugKeys[0]].slug[slugKeys[1]] = slug(val);
      } else {
        this[slugKeys].slug = slug(val);
      }
    },
    slugPaste(val, slugKeys = "editedItem") {
      if (typeof slugKeys === "object") {
        if (this[slugKeys[0]].slug[slugKeys[1]] === "") {
          this[slugKeys[0]].slug[slugKeys[1]] = slug(val);
        }
      } else {
        if (this[slugKeys].slug === "") {
          this[slugKeys].slug = slug(val);
        }
      }
    },
  },
};
